import React, { createContext, useState, useEffect } from "react";
import Fuse from "fuse.js";
import axios from "axios";

const CartContext = createContext();
export default CartContext;

export function CartProvider({ children }) {
  // export function CartProvider() {

  const OrderStates = {
    RECEIVED: 1,
    STARTPACKAGING: 2,
    CANCELLED: 3,
    PACKAGED: 4,
    SHORTAGEITEMS: 5,
    SENTDELIVERY: 6,
    DELIVEREDANDPAID: 7,
    DELIVEREDBUTUNPAID: 8,
    PAYMENTRECEIVED: 9,
    REFUNDED: 10,
    CLOSED: 11,
    RETURNED: 12,
    ADDTOINVENTORY: 13,
    ADDEDTOINVENTORY: 14,
    CANCELPACKAGE: 15,
    CANCELDELIVERY: 16,
    CANCELLEDREFUNDNOTREQUIRED: 17,
    CANCELANDCLOSE: 18,
    REFUNDEDANDCLOSED: 19,
    RETURNEHANDEDDANDREFUNDED: 20,
  };

  const [myCart, setMyCart] = useState([]);
  const [posCarts, setPosCarts] = useState([
    { name: "POS1", orderType: "InStore", cart: [], coupon: {} },
    { name: "POS2", orderType: "InStore", cart: [], coupon: {} },
    { name: "POS3", orderType: "InStore", cart: [], coupon: {} },
    { name: "POS4", orderType: "InStore", cart: [], coupon: {} },
    { name: "POS5", orderType: "InStore", cart: [], coupon: {} },
  ]);
  const Environment = {
    isDev: false,
  };

  // #35579B
  const [themeBorderColor, setThemeBorderColor] = useState("#303030");
  const [selectedPOS, setSelectedPOS] = useState("POS1");
  const [hideFav, setHideFav] = useState(true);
  const [showNumberKeys, setShowNumberKeys] = useState(true);
  const [orderData, setOrderData] = useState([]);
  const [tableOrderData, setTableOrderData] = useState([]);
  const [tableOrderStatus, setTableOrderStatus] = useState("");
  const [selectedTable, setSelectedTable] = useState({});
  const [OrderStatus, setOrderStatus] = useState("");
  const [coupon, setCoupon] = useState({
    couponCode: "",
    couponValue: 0.0,
  });

  const [todayTotalSale, setTodayTotalSale] = useState(0);
  const [lastBillAmount, setLastBillAmount] = useState(0);
  const [forceUpdatePosCart, setForceUpdatePosCart] = useState(false);
  const [hideMainNavBar, setHideMainNavBar] = useState(false);
  const [showUserLoginNew, setShowUserLoginNew] = useState(true);
  const [cardWidthDesktop, setCardWidthDesktop] = useState("17rem");
  const [cardWidthMobile, setCardWidthMobile] = useState("12rem");
  const [AddedToCartMsg, setAddedToCartMsg] = useState("");
  const [prevPage, setPrevPage] = useState("");
  const [storeId, setStoreId] = useState("");

  const [guestUserTypeId, setGuestUserTypeId] = useState("2");
  const [store, setStore] = useState([]);
  const [brands, setBrands] = useState([]);
  const [theme, setTheme] = useState("");
  const [mainCategories, setMainCategories] = useState([{}]);
  const [subCategories, setSubCategories] = useState([]);
  const [posRoles, setPosRoles] = useState([]);
  const [mainMenus, setMainMenus] = useState([]);
  const [orderStatusTypes, setOrderStatusTypes] = useState([]);
  const [storeSettings, setStoreSettings] = useState("");
  const [shippingDetails, setshippingDetails] = useState({});
  const [taxDetails, settaxDetails] = useState({});
  const [wishListProductIds, setwishListProductIds] = useState([]);
  const [favProductList, setFavProductList] = useState([]);
  const [productGroups, setProductGroups] = useState([]);
  const [productGroupsAndProducts, setProductGroupsAndProducts] = useState([]);
  const [homePageLoaded, setHomePageLoaded] = useState(false);
  const [qtyInCartModified, setQtyInCartModified] = useState(false);
  const [prods, setProducts] = useState([]);
  const [prodsAll, setProductsAll] = useState([]);
  const [prodsBase, setProdsBase] = useState([]);
  const [prodsAllBase, setProdsAllBase] = useState([]);
  const [prodsTakeAway, setProdsTakeAway] = useState([]);
  const [prodsAllTakeAway, setProdsAllTakeAway] = useState([]);
  const [prodsHomeDelivery, setProdsHomeDelivery] = useState([]);
  const [prodsAllHomeDelivery, setProdsAllHomeDelivery] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [reloadProductListView, setReloadProductListView] = useState("false");
  //title to show on ProductListView
  const [prodListTitle, setProdListTitle] = useState("");
  //to identify what type of products to show on ProductListView. Filters will be decided based on this type
  const [productListViewType, setProductListViewType] = useState("");
  const [productListViewMainCategoryId, setProductListViewMainCategoryId] =
    useState("");
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    storeId: "",
    emailId: "",
    mobileNo: "",
    bearertoken: "",
    loginStatus: "inactive", //active/inactive
    hideSignupLink: "", //set "true" for hide; set "" for show
  });

  const [modeOfSale, setModeOfSale] = useState("PointOfSale");
  const [returantTables, setReturantTables] = useState([]);
  const [restaurantTableOrdereStatus, setRestaurantTableOrdereStatus] =
    useState([]);

  const [themeTemp, setThemeTemp] = useState({
    invertLogo: "100",
    invertMenuIconOnMobile: "85",
    hideProductCount: "true",
    mainCategoryDisplayShape: "rounded",
    subCategoryAndBrandDisplayShape: "rounded-circle",
    productCardBorderColor: "black",
    maincategoryNameHeight: "1",
    maincategoryNameHeightMobile: "1",
    subcategoryNameHeight: "1",
    subcategoryNameHeightMobile: "1",
  });

  const [guestUserData, setGuestUserData] = useState([]);
  const [mainCarousel, setMainCarousel] = useState([]);

  const [licenseKey, setLicenseKey] = useState(null);
  const [pcCode, setPcCode] = useState("a");
  const [isOfflineMode, setIsOfflineMode] = useState(false);
  const [offlineOrdersCount, setOfflineOrdersCount] = useState(0);

  const LoadOffLineData = () => {
    setStoreId(localStorage.getItem("StoreId"));
    setIsOfflineMode(true);
    setStore(JSON.parse(localStorage.getItem("Store")));
    setStoreSettings(JSON.parse(localStorage.getItem("StoreSettings")));
    var data1 = localStorage.getItem("Products");
    setProducts(JSON.parse(data1));
    setProductsAll(JSON.parse(data1));
    setProdsBase(JSON.parse(data1));
    setProdsAllBase(JSON.parse(data1));
    setPosRoles(JSON.parse(localStorage.getItem("PosRoles")));
    setOrderStatusTypes(JSON.parse(localStorage.getItem("OrderStatusTypes")));
    setMainCategories(JSON.parse(localStorage.getItem("MainCategories")));
    setSubCategories(JSON.parse(localStorage.getItem("SubCategories")));
    setBrands(JSON.parse(localStorage.getItem("Brands")));
    setshippingDetails(JSON.parse(localStorage.getItem("ShippingDetails")));
    settaxDetails(JSON.parse(localStorage.getItem("TaxDetails")));
    setReturantTables(JSON.parse(localStorage.getItem("ReturantTables")));
    setRestaurantTableOrdereStatus(
      JSON.parse(localStorage.getItem("RestaurantTableOrdereStatus"))
    );
  };

  const AddOfflineOrders = (str) => {
    var allorders = [];
    allorders = JSON.parse(localStorage.getItem("Orders"));
    if (allorders && allorders.length > 0) {
      allorders.push(str);
      localStorage.setItem("Orders", JSON.stringify(allorders));
      setOfflineOrdersCount(allorders.length + 1);
    } else {
      var firstOrder = [];
      firstOrder.push(str);
      localStorage.setItem("Orders", JSON.stringify(firstOrder));
      setOfflineOrdersCount(1);
    }
  };

  const SyncOrdersStart = () => {
    var allorders = [];
    allorders = JSON.parse(localStorage.getItem("Orders"));
    SyncOrders(allorders[0]);
  };

  const SyncOrders = (str) => {
    axios
      .post(process.env.REACT_APP_API + "Consumers/SubmitOrder", str)
      .then((response) => {
        if (response.status === 200) {
          var allorders = [];
          allorders = JSON.parse(localStorage.getItem("Orders"));
          //allorders = allorders.splice(0, 1);
          var allorders1 = deleteFromArray(allorders, 1);
          localStorage.setItem("Orders", JSON.stringify(allorders1));
          setOfflineOrdersCount(allorders1.length);
          SyncOrders(allorders1[0]);
        } else {
          console.log("SYNC with DB Failed!");
        }
      })
      .catch((error) => {
        console.log("Error SYNC with DB!", error);
      });
  };

  const deleteFromArray = (array, indexToDelete) => {
    var remain = new Array();
    for (var i = 0; i < array.length; i++) {
      if (i == indexToDelete - 1) {
        continue;
      }
      remain.push(array[i]);
    }
    return remain;
  };

  const GetStore = () => {
    fetch(process.env.REACT_APP_API + "Sales/GetStore/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setStore(data);
          localStorage.setItem("StoreId", storeId);
          localStorage.setItem("Store", JSON.stringify(data));
        }
      })
      .catch((error) => {
        alert("GetStores Error:: " + error);
      });
  };

  const GetStoreSettings = () => {
    fetch(process.env.REACT_APP_API + "Consumers/GetStoreSetting/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          document.title = data.storeName;
          document.getElementsByTagName("META")[4].content =
            data.storeName + "Online Store :)";
          document.getElementsByTagName("META")[5].content =
            data.storeName + "Online Store :)";
          setStoreSettings(data);
          localStorage.setItem("StoreSettings", JSON.stringify(data));
          if (data.selectedThemeType == "Product") {
            getProductTheme(data.themeName);
          } else if (data.selectedThemeType == "Custom") {
            getCustomTheme(data.customThemeName);
          }
        }
      })
      .catch((error) => {});
  };

  const SetHideFav = (status) => {
    setHideFav(status);
  };
  const SetShowNumberKeys = (status) => {
    setShowNumberKeys(status);
  };

  const SetTodayTotalSale = (amt) => {
    setTodayTotalSale(amt);
  };

  const GetTotalSalesToday = () => {
    var res = axios
      .get(
        process.env.REACT_APP_API + "Consumers/GetTotalSaleToday/" + userData.id
      )
      .then((response) => {
        if (response.status == 404) {
        } else if (response.status == 200) {
          // this.setState({ invoiceHtml: response.data });
          SetTodayTotalSale(response.data);
        } else {
        }
      })
      .catch((error) => {});
  };

  const SetLastBillAmount = (amt) => {
    setLastBillAmount(amt);
    localStorage.setItem("lastBillAmount", amt);
  };
  const ShowUserLoginNew = (status) => {
    setShowUserLoginNew(status);
  };
  const SetModeOfSale = (mode) => {
    setModeOfSale(mode);
  };
  const SetHideMainNavBar = (value) => {
    setHideMainNavBar(value);
  };

  const SetAddedToCartMsg = () => {
    setAddedToCartMsg("Added To Cart");
  };

  const SetHomePageLoadedStatus = () => {
    setHomePageLoaded(true);
  };

  const GetNewGuid = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  const SetSelectedTable = (data) => {
    setSelectedTable(data);
  };
  const GetPosUserRoles = () => {
    fetch(process.env.REACT_APP_API + "Common/GetPosUserRoles/")
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setPosRoles(data);
          localStorage.setItem("PosRoles", JSON.stringify(data));
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  const GetRestaturantTables = () => {
    fetch(
      process.env.REACT_APP_API + "Consumers/GetRestaturantTables/" + storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setReturantTables(data);
          localStorage.setItem("ReturantTables", JSON.stringify(data));
        }
      });
  };
  const GetRestaurantTableOrdereStatus = () => {
    fetch(
      process.env.REACT_APP_API + "Consumers/GetRestaurantTableOrdereStatus/"
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setRestaurantTableOrdereStatus(data);
          localStorage.setItem(
            "RestaurantTableOrdereStatus",
            JSON.stringify(data)
          );
        }
      });
  };
  const GetRestaurantTableOrdereStatusById = (statusID) => {
    return restaurantTableOrdereStatus.filter((f) => f.id == statusID)[0];
  };

  const getProductTheme = (themeName) => {
    if (themeName == "") {
      alert("ThemeName is not set!");
      return;
    }
    fetch(process.env.REACT_APP_API + "Consumers/GetProductTheme/" + themeName)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setTheme(data);
        }
      })
      .catch((error) => {
        console.error("GetTheme: There was an error!", error);
      });
  };
  const getCustomTheme = (themeName) => {
    if (themeName == "") {
      alert("ThemeName is not set!");
      return;
    }
    fetch(
      process.env.REACT_APP_API +
        "Consumers/GetCustomTheme/" +
        storeId +
        "," +
        themeName
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setTheme(data);
        }
      })
      .catch((error) => {
        console.error("GetTheme: There was an error!", error);
      });
  };

  const getShippingDetails = () => {
    fetch(
      process.env.REACT_APP_API + "StoreManager/GetShippingDetails/" + storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setshippingDetails(data);
          localStorage.setItem("ShippingDetails", JSON.stringify(data));
        }
      })
      .catch((error) => {
        console.error("GetShippingDetails!", error);
      });
  };
  const getTaxDetails = () => {
    fetch(process.env.REACT_APP_API + "StoreManager/GetTaxDetails/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          settaxDetails(data);
          localStorage.setItem("TaxDetails", JSON.stringify(data));
        }
      })
      .catch((error) => {
        console.error("GetShippingDetails!", error);
      });
  };

  const getBrands = () => {
    fetch(process.env.REACT_APP_API + "Consumers/GetBrands/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setBrands(data);
          localStorage.setItem("Brands", JSON.stringify(data));
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const GetMainCarousel = (targetDevice) => {
    var res = axios
      .get(
        process.env.REACT_APP_API +
          "Consumers/GetCarousel/" +
          storeId +
          "," +
          targetDevice
      )
      .then((response) => {
        if (response.status == 404) {
        } else if (response.status == 200) {
          setMainCarousel(response.data);
        } else {
        }
      })
      .catch((error) => {
        console.error("GetCarousel - There was an error! ", error);
        // alert("Error GetCarousel:" + error);
      });
  };

  const GetDefaultCurrency = () => {
    if (storeSettings.defaultCurrency == "INR") {
      return "₹";
    } else if (storeSettings.defaultCurrency == "USD") {
      return "$";
    }
  };

  const GetOrderStatusTypes = () => {
    var res = axios
      .get(process.env.REACT_APP_API + "StoreManager/GetOrderStatusTypes/")
      .then((response) => {
        if (response.status === 200) {
          setOrderStatusTypes(response.data);
          localStorage.setItem(
            "OrderStatusTypes",
            JSON.stringify(response.data)
          );
        }
      })
      .catch((error) => {
        console.error("GetOrderStatusTypes - There was an error! ", error);
      });
  };

  const GetMainMenus = () => {
    fetch(process.env.REACT_APP_API + "Consumers/GetMainMenus/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setMainMenus(data);
        }
      })
      .catch((error) => {
        alert("GetMainMenus:: " + error);
      });
  };

  const AddToWishList = (prod, seqNo) => {
    //check if product items exist, go for update
    const indexItem = wishListProductIds.findIndex(
      (item) => item.prodId === prod.id
    );

    if (indexItem != -1) {
      //already added...do nothing..
    } else {
      // add
      setwishListProductIds([
        ...wishListProductIds,
        { prodId: prod.id, seqNo: seqNo },
      ]);
    }

    //this is a callback. store the fav in local store
    setwishListProductIds((state) => {
      //todo: enable
      localStorage.setItem("localstoragefavlist", JSON.stringify(state));
      return state;
    });
  };

  const crypt = (salt, text) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);

    return text
      .split("")
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join("");
  };

  const decrypt = (salt, encoded) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded
      .match(/.{1,2}/g)
      .map((hex) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode) => String.fromCharCode(charCode))
      .join("");
  };

  const SetLicenseKey = (key) => {
    var r = crypt("Amal", key);
    setLicenseKey(r);
    localStorage.setItem("mysoul", r);
  };
  const SetPcCode = (key) => {
    localStorage.setItem("PcCode", key);
    setPcCode(key);
  };

  const GetLicenseKey = () => {
    if (licenseKey) {
      var r = decrypt("Amal", licenseKey);
      return r;
    } else return "";
  };

  const UpdateWishList = (wishListProductId) => {
    //check if product items exist, go for update
    const indexItem = wishListProductIds.findIndex(
      (item) => item.prodId === wishListProductId.prodId
    );

    if (indexItem != -1) {
      wishListProductIds[indexItem].seqNo = wishListProductId.seqNo;
      setwishListProductIds(wishListProductIds);
    }

    //this is a callback. store the fav in local store
    setwishListProductIds((state) => {
      //todo: enable
      localStorage.setItem("localstoragefavlist", JSON.stringify(state));
      return state;
    });
  };
  const SortWishList = () => {
    var sortedWishList = wishListProductIds.sort((a, b) =>
      a.seqNo > b.seqNo ? 1 : -1
    );

    setwishListProductIds(sortedWishList);

    //this is a callback. store the fav in local store
    setwishListProductIds((state) => {
      //todo: enable
      localStorage.setItem("localstoragefavlist", JSON.stringify(state));
      return state;
    });
  };
  const DeleteProductOnWishList = (id) => {
    setwishListProductIds(
      wishListProductIds.filter((productitem) => id != productitem.prodId)
    );

    //this is a callback. store the cart in local store
    setwishListProductIds((state) => {
      //todo: enable
      localStorage.setItem("localstoragefavlist", JSON.stringify(state));
      return state;
    });
  };

  // const StoreOrderSummary = (data) => {
  //   setOrderSummary(data);
  // };
  const StoreGuestUserData = (data) => {
    setGuestUserData(data);
  };
  const storeOrderInformation = (data) => {
    setOrderData(data);
  };
  const storeTableOrderInformation = (data) => {
    setTableOrderData(data);
  };
  const UpdateOrderStatus = (status) => {
    setOrderStatus(status);
  };
  const UpdateTableOrderStatus = (status) => {
    setTableOrderStatus(status);
  };
  const GetProductGroups = () => {
    fetch(process.env.REACT_APP_API + "Consumers/GetProductGroups/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setProductGroups(data);
        }
      })
      .catch((error) => {
        alert("GetProductGroups:: " + error);
      });
  };

  const GetProductGroupsAndProducts = () => {
    fetch(
      process.env.REACT_APP_API + "Consumers/GetProductGroupsDetail/" + storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else setProductGroupsAndProducts(data);
      })
      .catch((error) => {
        alert("GetProductGroupsAndProducts:: " + error);
      });
  };

  const searchOptions = {
    // isCaseSensitive: false,
    // includeScore: false,
    // shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    // minMatchCharLength: 1,
    // location: 0,
    // threshold: 0.6,
    // distance: 100,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    // fieldNormWeight: 1,
    keys: ["title", "author.firstName"],
  };

  const GetSearchResult = (pattenText) => {
    //setSearchData(options);
    const fuse = new Fuse(prods, searchOptions);
    setSearchData(fuse.search(pattenText));
  };

  const storePreviousPage = (value) => {
    setPrevPage(value);
  };

  const StoreDataOffline = () => {};

  const storeUserData = (data) => {
    setUserData({
      id: data.id,
      storeId: data.storeId,
      firstName: data.firstName,
      lastName: data.lastName,
      mobileNo: data.mobileNo,
      emailId: data.emailId,
      password: data.password,
      address: data.address,
      landmark: data.landmark,
      city: data.city,
      state: data.state,
      country: data.country,
      pinCode: data.pinCode,
      customerUserTypeId: data.customerUserTypeId,
      bearertoken: data.bearerToken,
      posRoleId: data.posRoleId,
      loginStatus: "active",
      hideSignupLink: "true", //set "true" for hide; set "" for show
    });

    //this is a callback. store the cart in local store
    setUserData((state) => {
      //todo: enable
      localStorage.setItem("localstorageUserData", JSON.stringify(state));
      setShowUserLoginNew(false);
      return state;
    });
  };
  const clearUserData = () => {
    setUserData({
      firstName: "",
      lastName: "",
      emailId: "",
      password: "",
      mobileNo: "",
      bearertoken: "",
      loginStatus: "inactive",
      hideSignupLink: "", //set "true" for hide; set "" for show
    });

    //this is a callback. store the cart in local store
    setUserData((state) => {
      //todo: enable
      localStorage.setItem("localstorageUserData", JSON.stringify(state));
      return state;
    });
  };

  const RefreshBearerTokenWithEmailId = (userData) => {
    if (userData !== "" && userData.loginStatus !== "active") return;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        storeId: userData.storeId,
        mobileNo: userData.mobileNo,
        emailId: userData.emailId,
      }),
    };

    fetch(
      process.env.REACT_APP_API + "Consumers/LoginPosByEmailId",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 404) {
          clearUserData();
          return;
        }
        storeUserData(data);
        console.log("storeUserData(data) success");
      })
      .catch((error) => {
        console.error("RefreshBearerToken: There was an error!", error);
        //alert("RefreshBearerToken err:" + error);
      });
  };
  const getMainCategories = () => {
    fetch(process.env.REACT_APP_API + "Consumers/GetMainCategory/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setMainCategories(data);
          localStorage.setItem("MainCategories", JSON.stringify(data));
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        alert("getMainCategories err:" + error);
      });
  };

  const getSubCategories = () => {
    fetch(process.env.REACT_APP_API + "Consumers/GetSubCategory/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setSubCategories(data);
          localStorage.setItem("SubCategories", JSON.stringify(data));
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        // alert("getSubCategories err:" + error);
      });
  };

  //convert multivariant product to single products.
  const ConvertMultivariantProducts = (data) => {
    var data1 = JSON.parse(JSON.stringify(data));
    console.log("multi");
    var filters = data1.filter((f) => f.productVariantType == 2);
    if (filters.length > 0) {
      //add single records
      filters.forEach((item) => {
        var newArr = [];
        item.productCustomFields.forEach((field) => {
          var newProd = JSON.parse(JSON.stringify(item));
          // newProd.id = "Custom-" + GetNewGuid();
          newProd.id = field.id;
          newProd.name +=
            " - [" +
            item.customField1 +
            "=" +
            field.customValue1 +
            "; " +
            (item.customField2
              ? item.customField2 + "=" + field.customValue2 + "]"
              : "]");
          newProd.mrp = field.mrp;
          newProd.discount = field.discount;
          newProd.inStockQty = field.inStockQty;
          newProd.productVariantType = 1;

          //add to prod list
          //setProducts([...prods, newProd]);
          newArr.push(newProd);
        });
        //delete multivariant record.
        data1 = data1.filter((productitem) => item.id != productitem.id);
        //insert the records
        newArr.forEach((f) => {
          data1.push(f);
        });
      });
    }
    return data1;
  };
  const GetAllProducts = () => {
    var querystring = `?MainCategoryId=-1&SubCategoryId=-1&filtertext=-1`;
    fetch(
      process.env.REACT_APP_API + "Consumers/GetProducts/" + storeId + "/POS"
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          var data1 = ConvertMultivariantProducts(data);
          setProducts(data1);
          setProductsAll(data1);
          setProdsBase(JSON.parse(JSON.stringify(data1)));
          setProdsAllBase(JSON.parse(JSON.stringify(data1)));
          localStorage.setItem("Products", JSON.stringify(data1));
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const SetSelectedPOS = (value) => {
    setSelectedPOS(value);

    setSelectedPOS((state) => {
      var pos = posCarts.filter((c) => c.name == value)[0];
      if (pos.orderType == "InStore") SetBaseMode(state);
      else if (pos.orderType == "TakeAway") SetTakeAwayMode(state);
      else if (pos.orderType == "HomeDelivery") SetHomeDeliveryMode(state);
      return state;
    });
  };

  const SetBaseModeForTable = () => {
    setProducts(JSON.parse(JSON.stringify(prodsBase)));
    setProductsAll(JSON.parse(JSON.stringify(prodsAllBase)));
  };
  const SetBaseMode = (posname) => {
    var selectedPos = posCarts.filter((c) => c.name == posname)[0];
    selectedPos.orderType = "InStore";
    setPosCarts(posCarts);
    setProducts(JSON.parse(JSON.stringify(prodsBase)));
    setProductsAll(JSON.parse(JSON.stringify(prodsAllBase)));

    setPosCarts((state) => {
      console.log("Hi");
      forceUpdateCartView();
      localStorage.setItem("localstoragecartitems", JSON.stringify(state));
      return state;
    });
  };
  const SetTakeAwayMode = (posname) => {
    var selectedPos = posCarts.filter((c) => c.name == posname)[0];
    selectedPos.orderType = "TakeAway";
    setPosCarts(posCarts);
    var p1 = JSON.parse(JSON.stringify(prodsBase));
    for (var i = 0; i < p1.length; i++) {
      p1[i].mrp =
        p1[i].mrp + (p1[i].mrp * p1[i].takeAwayChargeInPercentage) / 100;
    }
    setProducts(p1);
    setProductsAll(p1);

    setPosCarts((state) => {
      console.log("Hi");
      forceUpdateCartView();
      localStorage.setItem("localstoragecartitems", JSON.stringify(state));
      return state;
    });
  };
  const SetHomeDeliveryMode = (posname) => {
    var selectedPos = posCarts.filter((c) => c.name == posname)[0];
    selectedPos.orderType = "HomeDelivery";
    setPosCarts(posCarts);
    var p1 = JSON.parse(JSON.stringify(prodsBase));
    for (var i = 0; i < p1.length; i++) {
      p1[i].mrp =
        p1[i].mrp + (p1[i].mrp * p1[i].homeDeliveryChargeInPercentage) / 100;
    }
    setProducts(p1);
    setProductsAll(p1);

    setPosCarts((state) => {
      console.log("Hi");
      forceUpdateCartView();
      localStorage.setItem("localstoragecartitems", JSON.stringify(state));
      return state;
    });
  };

  const GetSelectedPosOrderType = () => {
    var selectedPos = posCarts.filter((c) => c.name == selectedPOS)[0];
    return selectedPos.orderType;
  };

  const GetProductsByMainCategoryId = (MainCategoryId) => {
    var category = mainCategories.find((f) => f.id == MainCategoryId);
    if (category != undefined) {
      setProdListTitle(category.name);
      setProductListViewType("MainCategory");
      setProductListViewMainCategoryId(MainCategoryId);
    }
    var tmpProds = prodsAll;
    setProducts(
      tmpProds.filter(
        (productitem) => MainCategoryId == productitem.mainCategoryId
      )
    );
  };
  const GetProductsBySubCategoryId = (CategoryId) => {
    var category = subCategories.find((f) => f.id == CategoryId);
    if (category != undefined) {
      setProdListTitle(category.name);
      setProductListViewType("SubCategory");
    }
    var tmpProds = prodsAll;
    setProducts(
      tmpProds.filter((productitem) => CategoryId == productitem.subCategoryId)
    );
  };
  const GetProductsByBrandId = (brandId) => {
    var brand = brands.find((f) => f.id == brandId);
    if (brand != undefined) {
      setProdListTitle(brand.brandName);
      setProductListViewType("Brand");
    }
    var tmpProds = prodsAll;
    setProducts(
      tmpProds.filter((productitem) => brandId == productitem.brandId)
    );
  };
  // const GetProductsByProductgroupId = (id) => {
  //   var category = productGroupsAndProducts.find((f) => f.id == id);
  //   if (category != undefined) {
  //     setProdListTitle(category.name);
  //     setProductListViewType("ProductGroup");
  //   }

  //   var tmpProds = productGroupsAndProducts.filter(
  //     (productgroup) => id == productgroup.id
  //   );

  //   var prodarray = [];
  //   tmpProds.map((t) => prodarray.push(t.prod));
  //   setProducts(prodarray);
  // };

  const GetProductsByProductgroupId = (id) => {
    var category = productGroups.find((f) => f.id == id);
    if (category != undefined) {
      setProdListTitle(category.name);
      setProductListViewType("ProductGroup");
    }

    var prodarray = [];
    category.productGroupAndProductsMaps.map((m) => {
      var pd = prodsAll.find((f) => f.id == m.productId);
      if (pd != undefined) prodarray.push(pd);
    });
    setProducts(prodarray);
  };

  const UpdateReloadProductListView = (val) => {
    setReloadProductListView(val);
  };

  const GetProducts = (MainCategoryId, SubCategoryId, filtertext) => {};

  //create your forceUpdate hook
  function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue((value) => value + 1); // update the state to force render
  }

  const SetForceUpdatePosCart = (status) => {
    setForceUpdatePosCart(status);
  };

  const forceUpdateCartView = useForceUpdate();

  const addToPOSCart = (name, cartitem) => {
    var myCart = getPOSCartByName(name);
    //check if product items exist, go for update
    const indexItem = myCart.findIndex(
      (item) => item.prod.id == cartitem.prod.id
    );
    if (indexItem != -1) {
      //update
      myCart.map((item) => {
        if (item.prod.id == cartitem.prod.id) {
          item.qty = Number(item.qty) + Number(cartitem.qty);
          setPosCarts(posCarts);
        }
      });
      // return false;
    } else {
      // add
      //setMyCart([...myCart, cartitem]);
      myCart.push(cartitem);
      setPosCarts(posCarts);
    }

    //this is a callback. store the cart in local store
    setPosCarts((state) => {
      console.log("Hi");
      forceUpdateCartView();
      SetForceUpdatePosCart(true);
      localStorage.setItem("localstoragecartitems", JSON.stringify(state));
      return state;
    });
  };

  const UpdatePOSCartWithNewPrice = (name, cartitem) => {
    var myCart = getPOSCartByName(name);
    myCart.map((item) => {
      var pd = prodsAll.filter((f) => f.id == item.prod.id);
      if (pd.length > 0) item.prod.mrp = pd[0].mrp;
    });

    setPosCarts(posCarts);
    //this is a callback. store the cart in local store
    setPosCarts((state) => {
      console.log("Hi");
      forceUpdateCartView();
      localStorage.setItem("localstoragecartitems", JSON.stringify(state));
      return state;
    });
  };

  const getPOSCartByName = (name) => {
    return posCarts.filter((c) => c.name == name)[0].cart;
  };

  const addCustomItemToMyCart = (cartitem) => {
    var t = posCarts.filter((c) => c.name == selectedPOS)[0];
    t.cart.push(cartitem);
    setPosCarts(posCarts);
    setPosCarts((state) => {
      localStorage.setItem("localstoragecartitems", JSON.stringify(state));
      forceUpdateCartView();
      return state;
    });
  };

  const ClearCartAndOrderDetails = (name) => {
    // var myCart = getPOSCartByName(name);
    // myCart.forEach((e1, idx, arr) => {
    //   myCart.splice(idx, 0);
    //   myCart.pop();
    // });
    // myCart = myCart.filter((f) => f.qty == 0);

    var t = posCarts.filter((c) => c.name == name)[0];
    t.cart = t.cart.filter((f) => f.qty == 0);

    setPosCarts(posCarts);
    setPosCarts((state) => {
      localStorage.setItem("localstoragecartitems", JSON.stringify(state));
      forceUpdateCartView();
      return state;
    });
  };
  const ClearTableCartAndOrderDetails = () => {
    // setMyCart([]);
    // setCoupon({ couponCode: "", couponValue: 0 });
  };

  const ClearAddedToCartMsg = () => {
    setAddedToCartMsg("");
  };

  const deleteItemOnMyCart = (id) => {
    var t = posCarts.filter((c) => c.name == selectedPOS)[0];

    t.cart = t.cart.filter((productitem) => id != productitem.prod.id);

    setPosCarts(posCarts);
    setPosCarts((state) => {
      localStorage.setItem("localstoragecartitems", JSON.stringify(state));
      return state;
    });
  };

  const SetQtyInCartModified = (status) => {
    setQtyInCartModified(status);
  };
  const UpdateQtyToCart = (prodId, qty) => {
    if (qty >= 0) {
      myCart.map((item) => {
        if (item.prod.id == prodId) {
          item.qty = qty;
        }
      });
      setMyCart(myCart);
    }
    if (qty == 0) {
      deleteItemOnMyCart(prodId);
    }
    SetQtyInCartModified(true);
  };

  const SetStateOfMyCart = (cart) => {
    //setMyCart(cart);
    var t = posCarts.filter((c) => c.name == selectedPOS)[0];
    t.cart = cart;

    setPosCarts(posCarts);
    setPosCarts((state) => {
      localStorage.setItem("localstoragecartitems", JSON.stringify(state));
      return state;
    });
  };

  const ClearCookies = () => {
    //ClearCookies
    localStorage.setItem("localstoragecartitems", "");
    localStorage.setItem("localstorageUserData", "");
  };

  const ValidateLogin = (emailId, mobileno, storeId) => {
    if (isOfflineMode == true) return;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        storeId: storeId,
        mobileNo: mobileno,
        emailId: emailId,
      }),
    };

    fetch(
      process.env.REACT_APP_API + "Consumers/LoginPosByEmailId",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 404 || data.status == 400) {
          this.setState({
            loginResultText: "Unable to Signup! Pleae try later.",
          });
          alert("Invalid User Account. Contact your Admin Manager! ");
          return;
        }

        storeUserData(data);
        if (
          data.posRoleId == 1 ||
          data.posRoleId == 2 ||
          data.posRoleId == null ||
          data.posRoleId == undefined
        )
          SetModeOfSale("PointOfSale");
        else if (data.posRoleId == 3) SetModeOfSale("TableOrder");
      })
      .catch((error) => {
        console.error("There was an error!", error);
        //alert("err:" + error);
      });
  };

  // const ValidateLoginWithMobileNo = (mobileNo, emailId, storeId) => {
  //   const requestOptions = {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({
  //       storeId: storeId,
  //       mobileNo: mobileNo,
  //       emailId: emailId,
  //     }),
  //   };
  //   fetch(
  //     process.env.REACT_APP_API + "Consumers/LoginWithPhoneNumber",
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.status == 404 || data.status == 400) {
  //         storeUserData({
  //           firstName: "",
  //           lastName: "",
  //           emailId: "",
  //           mobileNo: "",
  //           bearertoken: "",
  //           loginStatus: "inactive", //active/inactive
  //           hideSignupLink: "", //set "true" for hide; set "" for show
  //         });
  //         return;
  //       }
  //       storeUserData(data);
  //     })
  //     .catch((error) => {
  //       return;
  //     });
  // };

  //when page is reloaded, restore the cart from cookie
  //todo: clear the cookie when localstorage is older than a week/month
  useEffect(() => {
    let lkey = localStorage.getItem("mysoul");
    setLicenseKey(lkey);

    let pccode = localStorage.getItem("PcCode");
    if (pccode) setPcCode(pccode);

    var lastBill = localStorage.getItem("lastBillAmount");
    if (lastBill) setLastBillAmount(lastBill);

    let offlineOrders = localStorage.getItem("Orders");
    if (offlineOrders) setOfflineOrdersCount(JSON.parse(offlineOrders).length);

    let v1 = localStorage.getItem("localstoragecartitems");
    if (v1 !== null && v1 !== `""` && v1 !== ``) setPosCarts(JSON.parse(v1));

    let wishlist = localStorage.getItem("localstoragefavlist");
    if (wishlist !== null && wishlist !== `""` && wishlist !== ``) {
      setwishListProductIds(JSON.parse(wishlist));
      console.log(wishlist);
    }

    v1 = localStorage.getItem("localstorageUserData");
    if (v1 !== null && v1 !== `""` && v1 !== ``) {
      setUserData(JSON.parse(v1));

      setUserData((state) => {
        if (
          (state.mobileNo != "" && state.mobileNo != undefined) ||
          (state.emailId != "" && state.emailId != undefined)
        ) {
          ValidateLogin(state.emailId, state.mobileNo, state.storeId);
        }
        return state;
      });
    }
  }, []);

  const ClearAllCookies = () => {
    //1. clear Cart Items
    localStorage.setItem("localstoragecartitems", null);
  };

  const getTotalQtyInCart = (myCart) => {
    if (myCart == undefined) return 0;
    var totqty = 0;
    if (myCart !== null && (myCart !== `""`) & (myCart !== ``)) {
      myCart.map((item) => {
        totqty = totqty + Number(item.qty);
      });
    }
    return totqty;
  };
  const getTotalSavingsFromCart = (myCart) => {
    var totsav = 0;
    if (myCart !== null && (myCart !== `""`) & (myCart !== ``)) {
      myCart.map((item) => {
        totsav =
          totsav +
          (Number(item.prod.mrp) *
            Number(item.qty) *
            Number(item.prod.discount)) /
            100;
      });
    }
    return Number(totsav);
  };
  const getSubTotalPriceInCart = (myCart) => {
    var totprice = 0;
    if (myCart !== null && (myCart !== `""`) & (myCart !== ``)) {
      myCart.map((item) => {
        totprice =
          totprice +
          Number(item.qty) *
            (Number(item.prod.mrp) -
              (Number(item.prod.mrp) * Number(item.prod.discount)) / 100);
      });
    }
    return Number(totprice);
  };

  const getDeliveryAmount = (myCart) => {
    if (
      shippingDetails.freeDelivery == undefined ||
      shippingDetails.freeDelivery == true
    ) {
      return 0;
    } else if (
      shippingDetails.deliveryCharges == true &&
      GetSelectedPosOrderType() == "HomeDelivery"
    ) {
      if (getSubTotalPriceInCart(myCart) > shippingDetails.freeDeliveryAbove) {
        return 0;
      } else {
        return shippingDetails.deliveryChargePerOrder;
      }
    } else return 0;
  };

  const getTaxAmount = (myCart, couponValue) => {
    if (taxDetails.inclusiveOfTax == true) return 0;

    var result =
      (getTotalAmountWithoutTax(myCart, couponValue) *
        taxDetails.taxPercentage) /
      100;
    return result;
  };

  const getTotalAmountWithoutTax = (myCart, couponValue) => {
    var result =
      getSubTotalPriceInCart(myCart) +
      getDeliveryAmount(myCart) -
      Number(couponValue);
    return result;
  };

  const getTotalAmountWithTax = (myCart, couponValue) => {
    var result =
      getTotalAmountWithoutTax(myCart, couponValue) +
      getTaxAmount(myCart, couponValue);
    return result;
  };

  const StoreCouponDetails = (couponCode, couponValue) => {
    // setCoupon({ couponCode: couponCode, couponValue: couponValue });
  };

  const getTotalQtyInCartTable = (myCart) => {
    var totqty = 0;
    myCart.map((item) => {
      totqty = totqty + Number(item.qty);
    });
    return totqty;
  };

  const getTaxAmountTable = (myCart, couponValue) => {
    if (taxDetails.inclusiveOfTax == true) return 0;

    var result =
      (getTotalAmountWithoutTaxTable(myCart, couponValue) *
        taxDetails.taxPercentage) /
      100;
    return result;
  };
  const getTotalAmountWithoutTaxTable = (myCart, couponValue) => {
    var result = getSubTotalPriceInCartTable(myCart) - Number(couponValue);
    return result;
  };

  const getTotalAmountWithTaxTable = (myCart, couponValue) => {
    var result =
      getTotalAmountWithoutTaxTable(myCart, couponValue) +
      getTaxAmountTable(myCart, couponValue);
    return result;
  };

  const getTotalSavingsFromCartTable = (myCart) => {
    var totsav = 0;
    myCart.map((item) => {
      totsav =
        totsav +
        (Number(item.mrp) * Number(item.qty) * Number(item.discount)) / 100;
    });

    return Number(totsav);
  };

  const getSubTotalPriceInCartTable = (myCart) => {
    var totprice = 0;
    myCart.map((item) => {
      totprice =
        totprice +
        Number(item.qty) *
          (Number(item.mrp) - (Number(item.mrp) * Number(item.discount)) / 100);
    });
    return Number(totprice);
  };

  const getTableById = (id) => {
    return returantTables.filter((f) => f.id == id)[0];
  };

  const DeleteRestaurantTableOrder = (tableId) => {
    axios
      .post(
        process.env.REACT_APP_API +
          "Consumers/DeleteRestaurantTableOrder/" +
          tableId
      )
      .then((response) => {
        if (response.status === 200) {
          console.log("DeleteRestaurantTableOrder success");
        } else {
          console.log("DeleteRestaurantTableOrder failed");
        }
      })
      .catch((error) => {
        console.error("DeleteRestaurantTableOrder:There was an error!", error);
      });
  };

  const GetInDateFormat = (D) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = D.getDate();

    const monthIndex = D.getMonth();
    const monthName = monthNames[monthIndex];

    const year = D.getFullYear();

    return `${day}-${monthName}-${year}`;
  };

  const updateMyCart = (cartitem) => {};

  return (
    <CartContext.Provider
      value={{
        isOfflineMode,
        pcCode,
        todayTotalSale,
        offlineOrdersCount,
        storeId,
        selectedPOS,
        themeBorderColor,
        forceUpdatePosCart,
        hideFav,
        guestUserTypeId,
        searchData,
        myCart,
        mainCategories,
        subCategories,
        productGroups,
        productGroupsAndProducts,
        homePageLoaded,
        prods,
        prodsAll,
        posRoles,
        userData,
        prevPage,
        orderData,
        OrderStatus,
        guestUserData,
        AddedToCartMsg,
        reloadProductListView,
        prodListTitle,
        productListViewType,
        productListViewMainCategoryId,
        wishListProductIds,
        mainMenus,
        mainCarousel,
        orderStatusTypes,
        cardWidthDesktop,
        cardWidthMobile,
        store,
        posCarts,
        brands,
        theme,
        themeTemp,
        storeSettings,
        shippingDetails,
        taxDetails,
        coupon,
        showUserLoginNew,
        qtyInCartModified,
        returantTables,
        restaurantTableOrdereStatus,
        modeOfSale,
        tableOrderData,
        tableOrderStatus,
        selectedTable,
        hideMainNavBar,
        restaurantTableOrdereStatus,
        Environment,
        OrderStates,
        lastBillAmount,
        licenseKey,
        showNumberKeys,
        GetStore,
        SetLicenseKey,
        GetLicenseKey,
        RefreshBearerTokenWithEmailId,
        storeUserData,
        clearUserData,
        addToPOSCart,
        deleteItemOnMyCart,
        forceUpdateCartView,
        GetPosUserRoles,
        getTotalQtyInCart,
        getSubTotalPriceInCart,
        getTotalSavingsFromCart,
        getMainCategories,
        getSubCategories,
        GetProductGroups,
        GetAllProducts,
        GetProductsByMainCategoryId,
        GetProductsBySubCategoryId,
        GetProductsByProductgroupId,
        GetProducts,
        GetSearchResult,
        ClearCartAndOrderDetails,
        storePreviousPage,
        StoreGuestUserData,
        UpdateOrderStatus,
        storeOrderInformation,
        ClearAddedToCartMsg,
        UpdateReloadProductListView,
        AddToWishList,
        DeleteProductOnWishList,
        UpdateWishList,
        SortWishList,
        setStoreId,
        GetMainMenus,
        GetMainCarousel,
        GetOrderStatusTypes,
        setProducts,
        GetProductGroupsAndProducts,
        getBrands,
        GetStoreSettings,
        getShippingDetails,
        getTaxDetails,
        getDeliveryAmount,
        getTaxAmount,
        getTotalAmountWithoutTax,
        getTotalAmountWithTax,
        StoreCouponDetails,
        SetStateOfMyCart,
        GetProductsByBrandId,
        ShowUserLoginNew,
        GetDefaultCurrency,
        SetHomePageLoadedStatus,
        UpdateQtyToCart,
        SetQtyInCartModified,
        addCustomItemToMyCart,
        GetNewGuid,
        GetRestaturantTables,
        GetRestaurantTableOrdereStatus,
        SetModeOfSale,
        getTotalQtyInCartTable,
        getTaxAmountTable,
        getTotalAmountWithoutTaxTable,
        getTotalAmountWithTaxTable,
        getTotalSavingsFromCartTable,
        getSubTotalPriceInCartTable,
        getTableById,
        storeTableOrderInformation,
        UpdateTableOrderStatus,
        SetSelectedTable,
        SetHideMainNavBar,
        DeleteRestaurantTableOrder,
        GetRestaurantTableOrdereStatusById,
        getPOSCartByName,
        SetSelectedPOS,
        SetBaseMode,
        SetTakeAwayMode,
        SetHomeDeliveryMode,
        GetSelectedPosOrderType,
        SetBaseModeForTable,
        UpdatePOSCartWithNewPrice,
        GetInDateFormat,
        SetHideFav,
        SetShowNumberKeys,
        SetForceUpdatePosCart,
        SetLastBillAmount,
        LoadOffLineData,
        AddOfflineOrders,
        SyncOrdersStart,
        SetPcCode,
        SetTodayTotalSale,
        GetTotalSalesToday,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
