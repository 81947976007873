import React, { Component } from "react";
import CartContext from "./CartContext";
import {
  Stack,
  Button,
  Table,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";

const WAIT_INTERVAL = 500;

export class OrderSummaryTO extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      couponCode: "",
      couponValue: 0,
      couponData: "",
    };
  }

  componentDidMount() {}

  StoreCouponDetails = (data) => {
    this.setState({ couponData: data }, () => {
      if (this.state.couponData.couponValueType == 1) {
        if (
          this.state.couponData.amount >
          this.context.getSubTotalPriceInCartTable(this.props.myCart)
        ) {
          alert(
            "Coupon Value is " +
              this.state.couponData.amount +
              " which is greater than items in Cart. Add more items to Cart to use this Coupon!"
          );
          this.setState({ couponCode: "" });
          return;
        }
        this.setState(
          { couponValue: Math.round(this.state.couponData.amount, 0) },
          () => {
            this.props.StoreCouponDetails(
              this.state.couponCode,
              this.state.couponValue
            );
          }
        );
      } else if (this.state.couponData.couponValueType == 2) {
        this.setState(
          {
            couponValue: Math.round(
              (this.context.getSubTotalPriceInCartTable(this.props.myCart) *
                this.state.couponData.discount) /
                100,
              0
            ),
          },
          () => {
            this.props.StoreCouponDetails(
              this.state.couponCode,
              this.state.couponValue
            );
          }
        );
      }
    });
  };
  OnClickCouponApply = () => {
    if (this.state.couponCode == "" || this.state.couponCode == undefined)
      return;
    fetch(
      process.env.REACT_APP_API +
        "Consumers/GetCoupon/" +
        this.context.storeId +
        "/" +
        this.state.couponCode
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 404) {
          alert("invalid coupon");
          this.setState({ couponCode: "" });
          return;
        }
        this.StoreCouponDetails(data);
      })
      .catch((error) => {
        console.error("OnClickCouponApply error!", error);
        alert("OnClickCouponApply err:" + error);
      });
  };

  onCouponCodeChange = (e) => {
    this.setState({ couponCode: e.target.value }, () => {
      this.setState({ couponValue: 0 });

      this.props.StoreCouponDetails(e.target.value, 0);
    });
  };

  render() {
    return (
      <>
        <div className=" w-100 m-0 p-0">
          {window.innerWidth > 1100 ? (
            <>
              <div
                className="p-0 m-0 border-0"
                style={{ backgroundColor: this.context.themeBorderColor }}
              >
                <Row className="text-white alignverticalmiddle1">
                  <Col className="text-center border-0 border-secondary">
                    Sub.Total <br />
                    <h4>
                      <b>
                        {Number(
                          this.context.getSubTotalPriceInCartTable(
                            this.props.myCart
                          )
                        ).toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </b>
                    </h4>
                  </Col>
                  <Col className="text-center border-0 border-secondary">
                    Tot.Qty <br />
                    <h4>
                      <b>
                        {Number(
                          this.context.getTotalQtyInCartTable(this.props.myCart)
                        ).toFixed(0)}
                      </b>
                    </h4>
                  </Col>
                  {this.context.storeSettings.hideCouponCodeField == true ? (
                    ""
                  ) : (
                    <>
                      <Col className="text-center border-0 border-secondary">
                        Coupon <br />
                        <h4>
                          <Stack direction="horizontal">
                            <Form.Control
                              type="text"
                              placeholder=""
                              className="bg-white text-center"
                              value={this.state.couponCode}
                              // size="lg"
                              onChange={(e) => this.onCouponCodeChange(e)}
                            />
                            &nbsp;
                            <Button
                              className="bg-success text-white"
                              size="sm"
                              onClick={(e) => this.OnClickCouponApply(e)}
                            >
                              Apply
                            </Button>
                          </Stack>
                        </h4>
                      </Col>

                      {this.state.couponValue > 0 ? (
                        <>
                          <Col className="text-center border-0 border-secondary">
                            Cou.Value
                            <h4>
                              <br />
                              <b>
                                {Math.round(
                                  this.state.couponValue,
                                  0
                                ).toLocaleString(
                                  this.context.storeSettings.defaultLocale,
                                  {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 1,
                                    style: "currency",
                                    currency:
                                      this.context.storeSettings
                                        .defaultCurrency,
                                  }
                                )}
                              </b>
                            </h4>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                  {this.context.taxDetails.inclusiveOfTax == true ? (
                    ""
                  ) : (
                    <>
                      <Col className="text-center border-0 border-secondary">
                        Tax ({this.context.taxDetails.taxPercentage}
                        %) <br />
                        <h4>
                          <b>
                            {Number(
                              this.context.getTaxAmountTable(
                                this.props.myCart,
                                this.state.couponValue
                              )
                            ).toLocaleString(
                              this.context.storeSettings.defaultLocale,
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 1,
                                style: "currency",
                                currency:
                                  this.context.storeSettings.defaultCurrency,
                              }
                            )}
                          </b>
                        </h4>
                      </Col>
                    </>
                  )}

                  <Col className="text-center border-0 border-secondary">
                    Savings
                    <br />
                    <h4>
                      <b>
                        {Number(
                          this.context.getTotalSavingsFromCartTable(
                            this.props.myCart
                          )
                        ).toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </b>
                    </h4>
                  </Col>
                  <Col
                    className="text-center border-0 border-secondary bg-success text-white"
                    // style={{backgroundColor:"yellow"}}
                  >
                    {/* Total
                    <br /> */}
                    <span style={{ fontSize: "40px" }}>
                      <b>
                        {Math.round(
                          this.context.getTotalAmountWithTaxTable(
                            this.props.myCart,
                            this.state.couponValue
                          ),
                          0
                        ).toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </b>
                    </span>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <>
              <Table className="border m-0 p-0 text-center" size="sm">
                <tbody>
                  <Row className="m-0 p-0 text-center bg-dark text-white">
                    <Col className="  border">
                      T.Qty
                      <br />
                      <b>
                        {Number(
                          this.context.getTotalQtyInCartTable(this.props.myCart)
                        ).toFixed(0)}
                      </b>
                    </Col>

                    <Col className="  border">
                      S.Tot
                      <br />
                      <b>
                        {Math.round(
                          this.context.getSubTotalPriceInCartTable(
                            this.props.myCart
                          ),
                          0
                        ).toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </b>
                    </Col>
                    <Col className="  border">
                      Tax({this.context.taxDetails.taxPercentage}
                      %)
                      <br />
                      <b>
                        {Number(
                          this.context.getTaxAmountTable(
                            this.props.myCart,
                            this.state.couponValue
                          )
                        ).toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </b>
                    </Col>
                    <Col className="  border">
                      To.Sav
                      <br />
                      <b>
                        {Number(
                          this.context.getTotalSavingsFromCartTable(
                            this.props.myCart
                          )
                        ).toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </b>
                    </Col>
                    <Col className="  border">
                      <span style={{ fontSize: "24px" }}>
                        <b>
                          {Math.round(
                            this.context.getTotalAmountWithTaxTable(
                              this.props.myCart,
                              this.state.couponValue
                            ),
                            0
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </b>
                      </span>
                    </Col>
                  </Row>
                </tbody>
              </Table>
              {/* <Container className="px-0 bg-white">
                <br></br>
                <Row className="py-1 ">
                  <Col xs={1}></Col>
                  <Col className=" text-start">
                    <Form.Label size="" className="">
                      <b>
                        Subtotal [
                        {this.context.getTotalQtyInCartTable(this.props.myCart)}{" "}
                        Items]:
                      </b>
                    </Form.Label>
                  </Col>
                  <Col xs={3} className="text-end">
                    <Form.Label size="" className="">
                      <h5>
                        {Math.round(
                          this.context.getSubTotalPriceInCartTable(
                            this.props.myCart
                          ),
                          0
                        ).toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </h5>
                    </Form.Label>
                  </Col>
                  <Col xs={3}></Col>
                </Row>
                {this.context.storeSettings.hideCouponCodeField == true ? (
                  ""
                ) : (
                  <>
                    <Row className="py-1">
                      <Col xs={1}></Col>
                      <Col className=" text-start">
                        <Form.Label size="" className="">
                          <b>Coupon Code (if any):</b>
                        </Form.Label>
                      </Col>
                      <Col xs={3} className="text-center">
                        <Form.Control
                          type="text"
                          placeholder=""
                          className="bg-white text-center"
                          value={this.state.couponCode}
                          size="lg"
                          onChange={(e) => this.onCouponCodeChange(e)}
                        />
                      </Col>
                      <Col xs={3}>
                        <Button
                          className="bg-danger text-white"
                          size=""
                          onClick={(e) => this.OnClickCouponApply(e)}
                        >
                          Apply
                        </Button>
                      </Col>
                    </Row>
                    {this.state.couponValue > 0 ? (
                      <>
                        <Row className="py-1">
                          <Col xs={1}></Col>
                          <Col className=" text-start">
                            <Form.Label size="" className="">
                              <b>Coupon Code value :</b>
                            </Form.Label>
                          </Col>
                          <Col xs={3} className="text-end">
                            <Form.Label size="" className="">
                              <h5>
                                {Math.round(
                                  this.state.couponValue,
                                  0
                                ).toLocaleString(
                                  this.context.storeSettings.defaultLocale,
                                  {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 1,
                                    style: "currency",
                                    currency:
                                      this.context.storeSettings
                                        .defaultCurrency,
                                  }
                                )}
                              </h5>
                            </Form.Label>
                          </Col>
                          <Col xs={3}></Col>
                        </Row>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                )}
                {this.context.taxDetails.inclusiveOfTax == true ? (
                  ""
                ) : (
                  <>
                    <Row className="py-1">
                      <Col xs={1}></Col>
                      <Col className=" text-start">
                        <Form.Label size="" className="">
                          <b>
                            Tax&nbsp; ({this.context.taxDetails.taxPercentage}
                            %) :
                          </b>
                        </Form.Label>
                      </Col>
                      <Col xs={3} className="text-end">
                        <Form.Label size="" className="">
                          <h5>
                            {Number(
                              this.context.getTaxAmountTable(
                                this.props.myCart,
                                this.state.couponValue
                              )
                            ).toLocaleString(
                              this.context.storeSettings.defaultLocale,
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 1,
                                style: "currency",
                                currency:
                                  this.context.storeSettings.defaultCurrency,
                              }
                            )}
                          </h5>
                        </Form.Label>
                      </Col>
                      <Col xs={3}></Col>
                    </Row>
                  </>
                )}
                <Row className="border m-0 p-0 w-100">
                  <Col xs={1}></Col>
                  <Col className=" text-start">
                    <Form.Label size="" className="">
                      <h3 className="my-3"> Total :</h3>
                    </Form.Label>
                  </Col>
                  <Col xs={3} className="text-end">
                    <Form.Label size="" className="my-3">
                      <h3>
                        <b>
                          {Math.round(
                            this.context.getTotalAmountWithTaxTable(
                              this.props.myCart,
                              this.state.couponValue
                            ),
                            0
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </b>
                      </h3>
                    </Form.Label>
                  </Col>
                  <Col xs={3}></Col>
                </Row>
                <Row className="border mx-0">
                  <Col xs={1}></Col>
                  <Col className=" text-start">
                    <Form.Label size="" className="">
                      <h5 className="my-3"> Total Savings:</h5>
                    </Form.Label>
                  </Col>
                  <Col xs={3} className="text-end">
                    <Form.Label size="" className="my-3">
                      <h5>
                        {Number(
                          this.context.getTotalSavingsFromCartTable(
                            this.props.myCart
                          )
                        ).toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </h5>
                    </Form.Label>
                  </Col>
                  <Col xs={3}></Col>
                </Row>
              </Container> */}
            </>
          )}
        </div>
      </>
    );
  }
}
